.kv-pair {
    /* Div wrapper for the key-value pair */
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    gap: 10px 20px;
    row-gap: 10px;
    column-gap: 20px;
}

.key {
    /* key/label styling */
    font-weight: 500;
    font-size: 18px;
    color: $primary;
    width: 200px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
}

.value {
    /* input styling */
    width: 100%;
    font-size: 16px;
    line-height: 1.25;
    flex-grow: 2;
}

esa-value-display {
    /* input styling */
    width: 100%;
    font-size: 16px;
    flex-grow: 2;
    line-height: 1.25;
    padding: 1.5rem 0 1.25rem 0;
}

.filter {
    /* Select w/ over 7 options auto sets a filter styling */
    padding: 0.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: 1;
    top: 0px;
    position: sticky;
    background-color: white;
    align-items: center;
}
