@import "scss/theme/custom-material-theme";

// VARIABLES & MIXINS
@import "scss/variables/variables";
@import "scss/mixins/mixins";

// THIRD PARTY INCLUDES (after variables)
@import "~font-awesome/css/font-awesome.css";
@import "~leaflet/dist/leaflet.css";
//@import "~leaflet-draw/dist/leaflet.draw.css";

// UTILITIES
@import "scss/utilities/utilities";

// CUSTOM BOOTSTRAP
@import "scss/bootstrap-custom/bootstrap-custom";

// BASE STYLING
@import "scss/typography/typography";
@import "scss/layout/layout";

// BASE COMPONENTS
@import "scss/forms/forms";
@import "scss/charts/charts";
@import "scss/tables/tables";
@import "scss/maps/maps";
@import "scss/elements/elements";

// HEADER & FOOTER
@import "scss/header/header";
@import "scss/footer/footer";

// PAGES
@import "scss/pages/pages";

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
}

footer {
  margin-top: auto;
}

.material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.mat-mdc-tooltip {
    font-size: 14px;
}

/* ESA Material Button Styling */

esa-material-button {
    .disabled {
        opacity: 0.5;
        cursor: default;
    }
    
    .clear-button {
        background-color: transparent;
        color: #555 !important;
    }
    
    .clear-button:hover {
        background-color: #e4e4e4;
        color: #555 !important;
    }
    
    .primary-button {
        background-color: #04608D;
        color: #fff !important;
    
        &.tab {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            padding: 0 16px;
        }
    }
    
    .primary-button:hover {
        background-color: #024263;
        color: #fff !important;
    }
    
    .secondary-button {
        background-color: #DCF0F5;
        color: #2D2D2D !important;
    
        &:not(.tab) {
            margin-left: 4px;
        }
    
        &.tab {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            padding: 0 16px;
        }
    }
    
    .secondary-button:hover {
        background-color: #c2e5ee;
        color: #2D2D2D !important;
    }
}


.white-snackbar {
    --mdc-snackbar-container-color: whitesmoke;
    --mdc-snackbar-supporting-text-color:  #333;
    --mat-snackbar-text: #333;
}

// This will hide the TinyMCE "UGPRADE" button, there's a but that makes the 2nd load of the editor always show the button regardless of the "promotion" value being set to false.
.tox .tox-promotion {
    display:none !important;
}