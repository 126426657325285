.site-footer {
    background-color: #2a2a2a;
    font-size: $type-size-200;
    color: white;
    padding: 2rem;
    min-height: 20vh;

    a {
        color: white;
    }

    .btn {
        display: inline-block;
        border: none;
        margin: 0;
        padding: 0;
        color: $white;
    }

    .sitemap {
        grid-column: 1 / span 12;

        @include tablet {
            grid-column: 1 / span 6;
            display: grid;
            gap: 2rem;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .footer-header {
            text-transform: uppercase;
            letter-spacing: 0.05em;
            font-size: $type-size-150;
            padding-bottom: 0.5rem;
        }

        li {
            & + li {
                padding-top: 0.5rem;
            }
        }

        a {
            font-size: $type-size-200;
        }
    }

    .credits {
        grid-column: 1 / span 12;

        @include tablet {
            grid-column: 7 / span 6;
            text-align: right;
        }

        .copyright {
            display: block;
            margin-bottom: 0.5rem;
            font-size: $type-size-150;
        }

        .version {
            display: block;
            margin-bottom: 0.5rem;
            font-size: $type-size-150;
        }

        .esa-logo {
            width: 4rem;
            display: block;
            margin-bottom: 0.5rem;

            @include tablet {
                margin-left: auto;
            }

            img {
                max-width: 100%;
                display: block;
            }
        }
    }
}
