.required:after {
    content: "\f0e7";
    font-weight: 900;
    margin-left: 5px;
    font-size: 10px;
    font-family: "Font Awesome 5 Free";
    vertical-align: top;
}

.form {
    .required-note {
        border-top: 1px solid rgba(black, 0.05);
        padding-top: 1rem;
    }

    &.grid-12 {
        gap: 3rem 2rem;
    }
}

