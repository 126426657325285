$primary: #04608D;
$secondary: #c4c4c4;
$info: #228be6;
$light: #f5f5f5;
$muted: #dee2e6;
$danger: #ff0000;
$success: #00cc00;
$warning: #ffff9f;

$account-dropdown-text: #fff;
$button-border-and-background: #04608D;
$button-hover-text-color: #ffffff;
$button-text-color: #fff;
$button-hover-background: #fff;
$button-toggle-hover: #637ca1;
$footer: #999999;
$header-background: #f0644b;
$header-gradient-start: transparent;
$header-gradient-end: transparent;

$header-nav-link: #fff;
$h4-styled: #194349;
$select: #1f4d3d;
$select-and-nav-dropdown-hover: #87a1cc;
$select-and-nav-hover: #87a1cc;
$text-muted: darken(#d3d3d3, 15%);
$text-warning: darken($warning, 20%);

$base-text: #000000;

$card-cap-bg: #dfdfdf;

$modal-header-background: #dfdfdf;
$link-color: #205c90;

$white: #fff;
