.copy {
    line-height: 1.25em;

    & > * + * {
        margin-top: 1.5rem;
    }

    &-1 {
        font-size: $type-size-400;
    }

    &-2 {
        font-size: $type-size-300;
    }

    &-3 {
        font-size: $type-size-200;
    }

    li + li {
        padding-top: 1rem;
    }

    &-55 {
        max-width: 55rem;
    }
}
